<template>
  <div style="width:100%">
    <div class="e-card">
      <div class="e-card-body" style="padding-top:35px">
        <div style="margin-bottom: 35px">
          <div class="handle-box">
            <span class="v-form-label">手机号 / 姓名:</span>
            <div>
              <el-input placeholder="手机号 / 姓名" class="input-with-select" size="small" style="width:265px"
                        v-model="pnName" clearable>
                <el-button slot="append" icon="el-icon-search" @click="searchEvent"></el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="e-table-wrapper">
          <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
            <thead>
            <tr>
              <th width="130" class="th-l">卡号</th>
              <th width="130" class="th-l">卡名称</th>
              <th width="130" class="th-l">顾客姓名</th>
              <th width="130" class="th-l">性别</th>
              <th width="130" class="th-l">手机号</th>
              <th width="130" class="th-l">卡内余额</th>
              <th width="130" class="th-l">赠送金余额</th>
              <th width="130" class="th-l">开卡日期</th>
              <th width="130" class="th-l">到期日期</th>
              <th width="130" class="th-l">备注</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
              <td class="td-l"><span>{{row.mcNo}}</span></td>
              <td class="td-l"><span>{{row.cardName}}</span></td>
              <td class="td-l"><span>{{row.memberName}}</span></td>
              <td class="td-l">
                <span v-if="row.sex==0">男</span>
                <span v-if="row.sex==1">女</span>
              </td>
              <td class="td-l">{{row.phone}}</td>
              <td class="td-l">{{row.balance}}</td>
              <td class="td-l">{{row.donationAmount}}</td>
              <td class="td-l">{{row.createTime}}</td>
              <td class="td-l">{{row.dueDate}}</td>
              <td class="td-l">{{row.remarks}}</td>
            </tr>
            </tbody>
          </table>
          <div class="pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :pager-count="7"
              layout="total, prev, pager, next,jumper"
              :total="pagination.count"
              :page-count="pagination.pages"
              :current-page="pagination.pageNumber"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMember">确 定</el-button>
            </span>
    </el-dialog>

  </div>

</template>

<script>
  import {getToken} from '../../../utils/auth'

  export default {
    name: "memberEntry",
    data() {
      return {
        pnName: '',


        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        addVisible: false,
        editVisible: false,
        delVisible: false,

        editItem: {},
        options: [{
          value: '1',
          label: '是'
        }, {
          value: '0',
          label: '否'
        }]
      }
    },
    async created() {
      this.searchEvent();
    },
    methods: {
      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          token: getToken(),
          pnName: this.pnName,
          isDel:0,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/api/getMemberCards", data)
        this.list = res.dataSet.list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pages: res.dataSet.pages
        }
      },

      addEvent() {
        this.$router.push('/customers/saveMember')
        this.addVisible = true;
      },

      editEvent(index) {
        this.editItem = this.list[index]
        this.editVisible = true;
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除 Member
      async delMember() {
        let data = {
          id: this.editItem.id
        };

        let res = await this.$post("/api/delete_mbMember", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

      closeInsert() {
        this.addVisible = false;
      },

      closeEdit() {
        this.editVisible = false;
      },

      addBbiSupplierSuccess() {
        this.closeInsert();
        this.searchEvent();
      },

      editBbiSupplierSuccess() {
        this.closeEdit();
        this.searchEvent();
      }
    },
  }

</script>

<style scoped>

  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
.off {
  background: #fff;
}
.on {
  background: #ececec;
}
.e-table-wrapper thead th{
  color: #fff;
  background: rgb(40,40,40,.7);
}
.el-button--primary {
    background-color:  rgb(40,40,40,.7);
    border-color: rgb(40,40,40,.1);
}
</style>
