<template>
  <div style="width:100%">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">手机号 / 姓名:</span>
              <div>
                <el-input placeholder="手机号 / 姓名" class="input-with-select" size="small" style="width:265px"
                          v-model="manufactorName" clearable>
                  <el-button slot="append" icon="el-icon-search" @click="searchEvent"></el-button>
                </el-input>
              </div>
              <el-row  type="flex" justify="end" style="margin-left: 30px">
                <el-button size="small" class="e-button" type="success" @click="addEvent()">新增顾客档案
                </el-button>
                <el-button size="small" class="e-button" type="primary">高级搜索
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="130" class="th-l">姓名</th>
                <th width="130" class="th-l">性别</th>
                <th width="130" class="th-l">手机号</th>
                <th width="130" class="th-l">客单价</th>
                <th width="130" class="th-l">消费金额</th>
                <th width="130" class="th-l">消费次数</th>
                <th width="130" class="th-l">上次消费</th>
                <th width="130" class="th-l">积分</th>
                <th width="130" class="th-l">注册日</th>
                <th width="180" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.memberName}}</span></td>
                <td class="td-l">
                  <span v-if="row.sex==0">男</span>
                  <span v-if="row.sex==1">女</span>
                </td>
                <td class="td-l">{{row.phone}}</td>
                <td class="td-l">{{row.unitPrice}}</td>
                <td class="td-l">{{row.consumptionAmount}}</td>
                <td class="td-l">{{row.consumptionTimes}}</td>
                <td class="td-l">{{row.lastConsumption}}</td>
                <td class="td-l">{{row.integral}}</td>
                <td class="td-l"><span>{{row.createTime}}</span></td>
                <td class="td-l">
                  <!--<el-button type="text" icon="el-icon-edit" @click="editEvent(index)">详情</el-button>-->
                  <el-button type="text" icon="el-icon-edit"  @click="removeEvent(index)">恢复</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
    </div>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">确定恢复这条信息？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMember">确 定</el-button>
            </span>
    </el-dialog>

  </div>

</template>

<script>
  import {getToken,getMemberId} from '../../../utils/auth'

  export default {
    name: "dmEntry",
    data() {
      return {
        id: '',
        manufactorName: '',
        telephone: '',
        phone: '',
        contacts: '',
        token:'',
        userId:'',

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        addVisible: false,
        editVisible: false,
        delVisible: false,

        editItem: {},
        options: [{
          value: '1',
          label: '是'
        }, {
          value: '0',
          label: '否'
        }]
      }
    },
     created() {
      this.token =getToken()
      this.userId =getMemberId()
      this.searchEvent();
    },
    methods: {
      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          token: this.token,
          userId: this.userId,
          manufactorName: this.manufactorName,
          telephone: this.telephone,
          contacts: this.contacts,
          phone: this.phone,
          isDel:1,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/api/getMbMemberForPage", data)
        this.list = res.dataSet.list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pages: res.dataSet.pages
        }
      },

      addEvent() {
        this.$router.push('/customers/saveMember')
        this.addVisible = true;
      },

      editEvent(index) {
        this.editItem = this.list[index]
        this.editVisible = true;
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除 Member
      async delMember() {
        let data = {
          id: this.editItem.id
        };
        let res = await this.$post("/api/hf_mbMember", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

      closeInsert() {
        this.addVisible = false;
      },

      closeEdit() {
        this.editVisible = false;
      },

      addBbiSupplierSuccess() {
        this.closeInsert();
        this.searchEvent();
      },

      editBbiSupplierSuccess() {
        this.closeEdit();
        this.searchEvent();
      }
    },
  }

</script>

<style scoped>

  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
.off {
  background: #fff;
}
.on {
  background: #ececec;
}
.e-table-wrapper thead th{
  color: #fff;
  background: rgb(40,40,40,.7);
}
.el-button--success {
    background-color: rgb(40,40,40,.7);
    border-color: rgb(40,40,40,.1);
}
.el-button--primary {
    background-color: rgb(40,40,40,.7);
    border-color:rgb(40,40,40,.1);
}
</style>
