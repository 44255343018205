<template>
  <div style="width:100%">
    <div class="dashboard-content">
      <div class="type-title" >
        <div style="width: 500px;margin:0 auto">
          <div class="e-tabs-wrapper">
            <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">顾客
            </div>
            <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">会员卡
            </div>
            <div class="e-tabs-item" :class="{'active':tabIndex==2}" @click="switchTable(2)">已删除顾客
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <TabMember v-if="tabIndex==0"/>
      <TabMemberCard v-if="tabIndex==1"/>
      <TabDelMember v-if="tabIndex==2"/>
    </div>
  </div>
</template>

<script>
  import TabMember from './member/memberEntry'
  import TabMemberCard from './memberCard/entry'
  import TabDelMember from './member/dmEntry'
  export default {
    name: 'dashboard',
    data() {
      return {
        tabIndex: 0,
        map: '',
        yesterdayMap: '',
      }
    },
    async created() {

    },
    methods: {
      switchTable(tabIndex) {
        this.tabIndex = tabIndex
      },

    },
    components: {
      TabMember,
      TabMemberCard,
      TabDelMember,
    }
  }

</script>


<style scoped>
  /*.dashboard-container {*/
  /*padding: 10px;*/
  /*height: 100%;*/
  /*background: #f2f2f2;*/
  /*box-sizing: border-box;*/
  /*}*/

  .dashboard-content {
    padding: 0 15px;
    background: #fff;
    border-bottom: #e4e4e4 solid 1px;
  }

  .function {
    padding: 0px 30px 20px;
    overflow: hidden;
  }

  .function li {
    padding-left: 26px;
    float: left;
    width: 33.3%;
    font-size: 12px;
    color: #474f52;
    box-sizing: border-box;
    line-height: 40px;
    /*background: url(icon.png) no-repeat left -8px;*/
  }

  .function li span {
    cursor: pointer;
  }

  .condition-container {
    padding: 10px 20px 20px;
    display: flex;
  }

  .condition-container dl {
    flex: 1;
    display: flex;
  }

  .condition-container dt {
    padding-right: 20px;
  }

  .condition-container dl .name {
    font-size: 13px;
    color: #444e57;
  }

  .condition-container dl .number {
    font-size: 18px;
    line-height: 36px;
    color: #1b80ff;
  }

  .condition-container dl .old {
    font-size: 13px;
    color: #706d6b;
  }

  .type-title {
    display: flex;
    justify-content: space-between;
    line-height: 50px;
    padding-left: 20px;
    text-align:center
  }

  .type-title b {
    padding: 0px 30px;
    font-size: 14px;
    color: #212540
  }

  .more {
    padding-right: 10px;
    font-size: 12px;
    color: #aca59f
  }

  .more-link {
    margin-right: 20px;
    font-size: 12px;
    color: #559cff
  }
</style>
